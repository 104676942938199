import React from 'react'
import styled from 'styled-components'
import OslerButton from '../components/OslerButton'

const OfferContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 3em;
    margin-top: 2em;
`

const OptionsCards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1400px) {
        flex-direction: column;
    }
`

const OptionCard = styled.div`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1em;
    padding: 3em 6em;
    width: 95%;
    background-color: white;
    margin: 0 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1400px) {
        width: 92%;
        margin-bottom: 2em;
    }

    @media (max-width: 500px) {
        width: 95%;
        margin-bottom: 2em;
        padding: 1em 2em;
    }


`

const Banner = styled.div`
    width: 100%;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-radius: 1em;

    background-color: ${props => {
        switch(props.color) {
            case 'blue':
                return '#165dce'
                break
            default:
                return 'green'
                break
    }}}
`

const BuyOptionTitle = styled.p`
    text-align: center;
    align-self: center;
    font-size: 1.6em;
    margin: 1em 0 2em 0;
    text-transform: uppercase;
`

const BuyPriceDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 1em 0 1.5em 0;
`

const BuyOptionPriceCrossed = styled.p`
    font-size: 1.8em;
    color: red;
    font-weight: bold;
    margin: 0;
    text-decoration: line-through;

    @media (max-width: 500px) {
        font-size: 1.6em;
    }
`

const BuyOptionPrice = styled.p`
    font-size: 3.3em;
    color: #165dce;
    font-weight: bold;
    margin: 0;

    @media (max-width: 500px) {
        font-size: 2.5em;
    }
`

const BuyInfo = styled.p`
    color: black;
    font-size: 1.3em;
    margin: 0 0 1em 0;
    text-align: left;

    @media (max-width: 500px) {
        font-size: 1.1em;
    }
`

export default function Offer(props) {
    function action(planID) {
        if (props.action) {
            props.action(planID)
        }
    }

    return (
        <OfferContainer>
            <OptionsCards>
                <OptionCard>
                    <Banner color = 'blue'>⭐ Plano até 2026 ⭐</Banner>
                    <BuyOptionTitle>Acesso até 31 de Jan/20<strong><u>26</u></strong></BuyOptionTitle>
                    <BuyPriceDiv>
                        {/* <BuyOptionPriceCrossed>R$3.000</BuyOptionPriceCrossed> */}
                        <BuyOptionPrice>R$3.600</BuyOptionPrice>
                    </BuyPriceDiv>

                    <BuyInfo>✓ ~16 meses de acesso</BuyInfo>
                    <BuyInfo>✓ Em até <u>12x</u> de R$300 no cartão</BuyInfo>
                    <BuyInfo>✓ Garantia de desconto e melhor preço na renovação</BuyInfo>
                    <BuyInfo>✓ Acesso a toda a plataforma e melhorias futuras </BuyInfo>

                    <OslerButton
                        bgColor='green'
                        text={props.buttonText}
                        onClick={() => action('plan-1')}
                        style={{ marginTop: '2em', padding: '2em'}}
                    />
                </OptionCard>


            </OptionsCards>
        </OfferContainer>
    )
}