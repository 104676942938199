import { useEffect, useRef, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux' 
import { ToastContainer } from 'react-toastify';

import './App.css';

import { useDispatch } from 'react-redux' 

import LoginScreen from './login/LoginScreen'
import MainScreen from './main/MainScreen';
import QuestionScreen from './tests/QuestionScreen';
import AnswerScreen from './tests/AnswerScreen';
import UserScreen from './user/UserScreen';
import Homepage from './website/Homepage';
import StatisticsScreen from './statistics/StatisticsScreen';
import AcademicTrackMain from './academic-track/AcademicTrackMain'

import { logOut } from './redux/userSlice'

import LoadingOverlay from '@ronchalant/react-loading-overlay'

import ConsultScreen from './tests/ConsultScreen';
import { checkActiveSubscription, getUserProfile, reloadUser, serializeUserProfile, subscriptionIsActive } from './firebase/firebaseUtils';
import { auth, db } from './firebase/firebase-setup';

import { setIsLoading } from './redux/loadingSlice'

import {ProtectedPath, LoginShield, UserScreenShield, LoadingShield, AppShield} from './router/ProtectedPath';
import FlashcardsScreen from './custom/FlashcardsScreen';
import ResidenciaScreen from './custom/ResidenciaScreen';
import ResetHistoryScreen from './user/ResetHistoryScreen';
import LikedScreen from './tests/LikedScreen';
import BuriedScreen from './tests/BuriedScreen';
import ErrorScreen from './main/ErrorScreen';
import LoggedOut from './main/LoggedOut';
import { saveUser } from './redux/userSlice';
import { useNavigate } from "react-router-dom";
import LoadingUserScreen from './main/LoadingUser';
import AmbassadorScreen from './ambassadors/AmbassadorScreen';
import FeedbackReviewScreen from './feedback/FeedbackReviewScreen';
import UserFeedbackReview from './user/UserFeedbackReview';
import UserAmbassador from './user/UserAmbassador';
import AmbassadorsDashboard from './ambassadors/AmbassadorsDashboard';
import { ThemeProvider } from 'styled-components';

import { createGlobalStyle } from 'styled-components';
import ClearHistory from './user/ClearHistory';
import CheckoutTemp from './user/checkout/CheckoutTemp';
import Mistakes from './mistakes/Mistakes';
import zIndex from '@mui/material/styles/zIndex';
import BedsideMain from './bedside/BedsideMain';
import BularioPage from './bedside/BularioPage';
import MedicamentoDetails from './bedside/MedicamentoDetails';
import CalcPage from './bedside/CalcPage';
import ListaCalcPage from './bedside/ListaCalcPage';
import Prescricao from './bedside/Prescricao';
import UsoClinicoList from './bedside/UsoClinicoList';
import PrescricaoDetalhes from './bedside/PrescricaoDetalhes';
import Prescrever from './bedside/Prescrever';
import CidsPage from './bedside/CidsPage';
import Notebook from './mistakes/Notebook';
import MaintenanceScreen from './main/MaintenanceScreen';


const GlobalStyle = createGlobalStyle`
  img {
    filter: ${props => props.theme.darkMode ? 'grayscale(30%) contrast(150%) brightness(80%)' : 'none'};
  }
`;


export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const user = useSelector(state => state.user.data)
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  const [isActive, setActiveState] = useState(false)
  const [listenerCalled, setListenerCalled] = useState(false)

  const darkMode = useSelector(state => state.theme.darkModeOn);
  const isLoading = useSelector (state => state.loading.isLoading)
  const profileListenerRegistered = useRef(false)


  useEffect(() => {
    // Adds an observer for changes to the signed-in user's ID token, 
    // which includes sign-in, sign-out, and token refresh events.
    //
    // É mais abrangente que auth.onAuthStateChange, que não é chamado
    // após modificações de token.
    const authUnsubscriber = auth.onIdTokenChanged(async user => {
      console.log("onIdTokenChanged: was signaled a change in user status.")

      if (user) {
        // Temos um usuário logado. Iremos baixar seu profile, e passar para o state
        // local e geral.
        
        // NÃO FAZEMOS REDIRECIONAMENTO DE PÁGINA. Primeiro, porque já temos redundâncias
        // para isso. Segundo, porque se esse método for chamado de modo futil pelo Firebase,
        // causariamos uma mudança de tela no meio da sessão.
        console.log("onIdTokenChanged: downloading profile from Firebase")
        const userData = await getUserProfile(user.uid, user.emailVerified)
        const isActive = subscriptionIsActive(userData)

        // se faz o dispatch do user antes do isActive, ele encaminha para a tela do /user
        // em retrospecto, talvez fosse melhor um state só
        setActiveState(isActive)
        dispatch(saveUser(userData))
        const upToDate = await checkVersion()

      }
      else {
        // Sabemos por fato que não há um usuário logado. Então, vamos limpar o state
        // local e geral.
        console.log('onIdTokenChanged: no user logged in, cleaning state.')
        dispatch(logOut())
      }

      // TODO Por que isso é necessário?
      dispatch(setIsLoading(false))

      // Registramos que o listener foi chamado, e decisões definitivas já
      // podem ser tomadas.
      setListenerCalled(true)
    })

    return () => {
      console.log('App: removing listeners')
      authUnsubscriber()
      dispatch(logOut()) 
    }
  }, [])


  async function checkVersion() {
    console.log('\tcheckVersion - Verificando versão do app...')

    const doc = await db.doc('appConfig/last_version').get()

    if (doc.exists) {
      const latestVersion = doc.data().last_version
      
      if (latestVersion !== '1.1.8') {
        console.log('\tcheckVersion - Iremos recarregar o app...')
        window.location.reload()
        return false
      }
      else {
        return true
      }
    } else {
      console.error("\tcheckVersion - ERRO - Documento de versão não encontrado!");
    }
  }


  // useEffect(() => {

  //   checkVersion()
  // }, [])



  useEffect(() => {
    // Estabelecemos um listener para o perfil do usuário. Assim, se houver mudanças no 
    // documento (e.g., isActive torna-se falso), somos notificados.

    // TODO Acho que isso só é chamado uma vez.
    if (user && !profileListenerRegistered.current) {
      console.log('Registering listener for user profile ' + user.id)

      profileListenerRegistered.current = true;


      const x = db.collection('users').doc(user.id).onSnapshot(doc => {
        console.log("Was notified of change in user profile")
        
        const data = serializeUserProfile(doc.data(), user.id, user.isEmailVerified)
        const isActive = subscriptionIsActive(data)

        dispatch(saveUser(data))
        setActiveState(isActive)
      })

      return x;
    }
  }, [])



  return (

    <ThemeProvider theme = {{ darkMode : darkMode}} >
      <GlobalStyle />

      <LoadingOverlay
        zIndex = {100}
        active={isLoading}
        spinner
        text='O interno está trabalhando...'
        className="overlay" >

      {maintenanceMode ? (
          <MaintenanceScreen />
        ) : (
          <Routes>

          <Route path = "/home"  element={<Homepage />} /> 

          <Route element={<AppShield user={user} isActive={isActive} listenerCalled={listenerCalled}/> }>
            <Route path = "/"            element={<MainScreen/>} />
            <Route path = "/app"         element={<MainScreen/>} />
            <Route path = "/track"       element={<AcademicTrackMain/>} />
            <Route path = "/question"    element={<QuestionScreen/>} />
            <Route path = "/answer"      element={<AnswerScreen />} />
            <Route path = "/consult"     element={<ConsultScreen />} />
            <Route path = "/flashcards"  element={<FlashcardsScreen />} />
            <Route path = "/notebook"    element={<Notebook />} />
            <Route path = "/mistakes"    element={<Mistakes />} />
            <Route path = "/residencia"  element={<ResidenciaScreen />} />
            <Route path = "/statistics"  element={<StatisticsScreen />} />
            <Route path = "/reset"       element={<ResetHistoryScreen />} />
            <Route path = "/liked"       element={<LikedScreen  />} />
            <Route path = "/buried"      element={<BuriedScreen />} />



            <Route path = "/bedside"     element={<BedsideMain />} />
            <Route path="/medicamentos"  element={<BularioPage />} />
            <Route path="/medicamentos/:medicamentoId" element={<MedicamentoDetails />} />
            <Route path="/calc/:tipo" element={<CalcPage />} />
            <Route path="/calculadoras" element={<ListaCalcPage />} />
            <Route path="/clinicolist" element={<UsoClinicoList />} />
            <Route path="/cidslist" element={<CidsPage />} />
            <Route path="/usos-clinicos/:usoClinico" element={<Prescricao />} />
            <Route path="/detalhes-medicamento/:medicamentoId" element={<PrescricaoDetalhes />} />
            <Route path="/prescrever/:medicamentoId/:tipo" element={<Prescrever />} />


            <Route path = "/feedback" element = { <UserFeedbackReview />} />
            <Route path = "/ambassador" element = { <AmbassadorScreen /> } />
            <Route path = "/clear" element = { <ClearHistory /> } />
            <Route path = "/ambassadors-dashboard" element = { <AmbassadorsDashboard /> } />
            <Route path = "/feedback-dashboard" element = {<FeedbackReviewScreen />} />
            <Route path = "/user" element={<UserScreen/>} />

          </Route>


          <Route element={<LoadingShield listenerCalled={listenerCalled} />}>
            <Route path = "/loading-user" element={<LoadingUserScreen />} />
          </Route>


          <Route path = "/error"        element={<ErrorScreen />} />
          <Route path = "/exited"       element={<LoggedOut />} />


          {/* <Route path = "/embaixadores"       element={<AmbassadorScreen />} /> */}
          

          <Route element={<LoginShield user={user} listenerCalled={listenerCalled} /> }>
            <Route path = "/login" element={<LoginScreen/>} />
          </Route>

        </Routes>

        )}


        <ToastContainer />
      

      </LoadingOverlay>
    </ThemeProvider>

  )
}
