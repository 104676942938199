import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import SideBar from './SideBar'
import TopBar from './TopBar'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import NotebookSlide from '../tests/NotebookSlide'
import { useDispatch, useSelector } from 'react-redux'
import { setShowNotebook } from '../redux/notebookSlice'





const ScrollableContent = styled.div`
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        ${backgroundWhiteBlack}   
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(142, 0, 17, 0.8);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(160, 29, 14, 0.8);
    }
`;


const StyledAppContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    width: 100vw;
    max-width: 100vw !important;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }

    // PRECISA ser isso, se não a side bar não estica
    min-height: 100vh;


    
    ${backgroundWhiteBlack}   
`;





const AppWrap = styled.div`
    display: flex;
    flex-direction: column;

    // FUNDAMENTAL que seja flex-start, senão oque fizemos do height e do scroll
    // acima dá merda
    justify-content: flex-start;
    align-items: center;

    // Tudo isso daqui é para o notebook slide.
    width: ${(props) => {
        let width = '100vw'

        if (props.showSideBar) {
            width += (props.showingSideBar ? ' - 170px' : ' - 60px')
        }
        
        width += (props.showingNotebook ? ' - 35vw' : '')

        return `calc(${width})`
    }};


    max-width: ${(props) => {
        let width = '100vw'

        if (props.showSideBar) {
            width += (props.showingSideBar ? ' - 170px' : ' - 60px')
        }
        
        width += (props.showingNotebook ? ' - 35vw' : '')

        return `calc(${width})`
    }};

    transition: width 0.3s ease;

    @media (max-width: 500px) {
        width: 98%;
        max-width: 98%;
    }
`


export default function AppContainer({children, showSideBar = true}) {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 500);
    const dispatch = useDispatch()
    const showNotebook = useSelector(state => state.notebook.showNotebook)
    const sideBarExpanded = useSelector(state => state.notebook.showSidebar)

    const scrollableRef = useRef(null);

    useEffect(() => {
        if (scrollableRef.current) {
            console.log('colocando foco')
          scrollableRef.current.focus()
        }
      }, [])
    
      return (
        // Definimos tabIndex = -1 no ScrollableContent para torná-lo focável via JavaScript.
        // Isso permite que chamemos scrollableRef.current.focus() e o elemento receba foco,
        // possibilitando a rolagem usando as teclas de seta do teclado sem que o usuário
        // precise clicar nele primeiro. Usar tabIndex = -1 garante que o elemento não seja
        // incluído na ordem de tabulação natural ao navegar com a tecla Tab, mantendo a
        // experiência de navegação por teclado intacta para os usuários.
        <ScrollableContent ref={scrollableRef} tabIndex="-1">
            <StyledAppContainer >

                {showSideBar && (isDesktop ? <SideBar /> : <TopBar />)}

                <AppWrap showingNotebook = {showNotebook} sideBarExpanded = {sideBarExpanded} showSideBar = {showSideBar} >
                    {children}
                </AppWrap>

                <NotebookSlide
                    isOpen = {showNotebook}
                    close = {() => dispatch(setShowNotebook(false))} />

            </StyledAppContainer>
        </ScrollableContent>
    );
}